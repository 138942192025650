import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import TwitchBanner from "../images/notserk_twitchbanner.png"
import NotserkIllustration from "../images/notserk-illustration.jpg"

const IndexPage = () => (
  <Layout>
      <SEO title="NOTSERK Gaming"
        pageBanner={TwitchBanner}
      />
      <div>
          <div className="row">
              <img src={TwitchBanner} alt=""/>
          </div>
      </div>
      <div className="container">
          <div className="row">
              <div className="col-sm-12 col-md-6">
                  <h1>About Notserk</h1>
                  <p>Growing up in the 90s, my first gaming experience was on PC with Wolfenstein 3D, next was Sony Playstation and Nintendo 64. Shortly after that my favorite console, the Dreamcast came out and it was a pivotal moment in my life of gaming. Ever since that time period I’ve always been more attracted to arcadey games.</p>
                  <p>I play mostly First person shooters but will occasionally play action adventure games as well. My audience is more adult focused and mainly focused on playing games for fun and discussing the industry and hanging out talking about various topics in the gaming space.
                  </p>
                  <div className="social-links">
                      {/*<a href="https://www.youtube.com/channel/UCHQnuYhOuAGEYPI6vMIce_A"><i className="fab fa-youtube-square"></i></a>*/}
                      <a href="https://www.twitch.tv/notserk"><i className="fab fa-twitch"></i></a>
                      <a href="https://www.youtube.com/channel/UCHQnuYhOuAGEYPI6vMIce_A"><i className="fab fa-youtube-square"></i></a>
                      <a href="https://twitter.com/notserk"><i className="fab fa-twitter-square"></i></a>
                      <a href="https://www.facebook.com/notserk"><i className="fab fa-facebook-square"></i></a>
                  </div>
              </div>
              <div className="col-sm-12 col-md-6">
                  <img src={NotserkIllustration} alt=""/>
              </div>
          </div>
          {/*<div className="row">*/}
          {/*    <h1 className="text-center">My Content</h1>*/}


          {/*</div>*/}
          {/*<div className="row">*/}
          {/*    <h1 className="text-center">Follow </h1>*/}

          {/*</div>*/}
          {/*<div className="row">*/}
          {/*    <h1 className="text-center">Blerdimension</h1>*/}

          {/*</div>*/}

      </div>
  </Layout>
)

export default IndexPage
